<template>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text">Adminstration</h4>
    </li>
    <MenuEntry to="/dashboard" name="Dashboard" icon="fa-home" />
    <MenuEntry to="/profile" name="Benutzerkonto" icon="fa-user" />
    <MenuEntry to="/invoices" name="Finanzen" icon="fa-file-invoice-dollar" />
    <MenuEntry to="/orders" name="Bestellungen" icon="fa-coins" />
    <MenuEntry
      to="/adjust-subscription"
      name="Abonement anpassen"
      icon="fa-sliders-h"
    />
    <MenuEntry
      to="/add-subscription-option"
      name="Optionen hinzufügen"
      icon="fa-plus"
    />

    <li class="menu-section">
      <h4 class="menu-text">Submito Profil</h4>
    </li>

    <MenuEntry to="/company" name="Firma" icon="fa-building" />
    <!--    <MenuEntry to="/company-references" name="Referenzprojekte" icon="fa-project-diagram" />-->
    <MenuEntry
      to="/company-details"
      name="Firmendetails"
      icon="fa-info-circle"
    />
    <MenuEntry to="/company-portrait" name="Firmaportrait" icon="fa-portrait" />
    <MenuEntry to="/branch-offices" name="Niederlassungen" icon="fa-building" />
    <MenuEntry to="/company-services" name="Dienstleistungen" icon="fa-list" />

    <li class="menu-section">
      <h4 class="menu-text">Weiteres</h4>
    </li>
    <MenuEntry
      to="/user-invitations"
      name="Vermittlerprogramm"
      icon="fa-heart"
    />
  </ul>
</template>

<script>
import MenuEntry from "@/view/layout/aside/MenuEntry";
export default {
  components: { MenuEntry },
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
