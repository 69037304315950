<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hallo,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ getFirstName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <!--        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />-->
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ getFullName.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Benutzerkonto
          <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <!--            <img-->
            <!--              class="symbol-label"-->
            <!--              :src="currentUserPersonalInfo.photo"-->
            <!--              alt=""-->
            <!--            />-->
            <!--            <i class="symbol-badge bg-success"></i>-->
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ getFullName }}
            </router-link>
            <div class="text-muted mt-1">
              {{ getEmail }}
            </div>
            <div class="mt-4">
              <!--              <a href="#" class="navi-item">-->
              <!--                <span class="navi-link p-0 pb-2">-->
              <!--                  <span class="navi-icon mr-1">-->
              <!--                    <span class="svg-icon svg-icon-lg svg-icon-primary">-->
              <!--                      &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
              <!--                      <inline-svg-->
              <!--                        src="media/svg/icons/Communication/Mail-notification.svg"-->
              <!--                      />-->
              <!--                      &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
              <!--                    </span>-->
              <!--                  </span>-->
              <!--                  &lt;!&ndash;                  <span class="navi-text text-muted text-hover-primary">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    &lt;!&ndash;                    {{ currentUserPersonalInfo.email }}&ndash;&gt;&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </span>&ndash;&gt;-->
              <!--                </span>-->
              <!--              </a>-->
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Abmelden
            </button>
          </div>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import UserService from "@/services/user.service";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    getFullName() {
      return UserService.getFirstName() + " " + UserService.getLastName();
    },
    getFirstName() {
      return UserService.getFirstName();
    },
    getEmail() {
      return UserService.getEmail();
    }
  }
};
</script>
